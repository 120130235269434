// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-close-account-js": () => import("./../../../src/pages/close-account.js" /* webpackChunkName: "component---src-pages-close-account-js" */),
  "component---src-pages-digi-padala-agent-js": () => import("./../../../src/pages/DigiPadalaAgent.js" /* webpackChunkName: "component---src-pages-digi-padala-agent-js" */),
  "component---src-pages-digi-padala-customer-js": () => import("./../../../src/pages/DigiPadalaCustomer.js" /* webpackChunkName: "component---src-pages-digi-padala-customer-js" */),
  "component---src-pages-digiprotec-info-js": () => import("./../../../src/pages/Digiprotec+Info.js" /* webpackChunkName: "component---src-pages-digiprotec-info-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modal-accounts-payable-officer-js": () => import("./../../../src/pages/modal/accounts-payable-officer.js" /* webpackChunkName: "component---src-pages-modal-accounts-payable-officer-js" */),
  "component---src-pages-modal-admin-staff-js": () => import("./../../../src/pages/modal/admin-staff.js" /* webpackChunkName: "component---src-pages-modal-admin-staff-js" */),
  "component---src-pages-modal-android-mobile-developer-js": () => import("./../../../src/pages/modal/android-mobile-developer.js" /* webpackChunkName: "component---src-pages-modal-android-mobile-developer-js" */),
  "component---src-pages-modal-business-development-manager-js": () => import("./../../../src/pages/modal/business-development-manager.js" /* webpackChunkName: "component---src-pages-modal-business-development-manager-js" */),
  "component---src-pages-modal-company-driver-js": () => import("./../../../src/pages/modal/company-driver.js" /* webpackChunkName: "component---src-pages-modal-company-driver-js" */),
  "component---src-pages-modal-comps-and-benefits-hr-js": () => import("./../../../src/pages/modal/comps-and-benefits-hr.js" /* webpackChunkName: "component---src-pages-modal-comps-and-benefits-hr-js" */),
  "component---src-pages-modal-devops-engineer-js": () => import("./../../../src/pages/modal/devops-engineer.js" /* webpackChunkName: "component---src-pages-modal-devops-engineer-js" */),
  "component---src-pages-modal-executive-assistant-js": () => import("./../../../src/pages/modal/executive-assistant.js" /* webpackChunkName: "component---src-pages-modal-executive-assistant-js" */),
  "component---src-pages-modal-financial-analyst-js": () => import("./../../../src/pages/modal/financial-analyst.js" /* webpackChunkName: "component---src-pages-modal-financial-analyst-js" */),
  "component---src-pages-modal-fullstack-software-engineer-js": () => import("./../../../src/pages/modal/fullstack-software-engineer.js" /* webpackChunkName: "component---src-pages-modal-fullstack-software-engineer-js" */),
  "component---src-pages-modal-graphic-designer-js": () => import("./../../../src/pages/modal/graphic-designer.js" /* webpackChunkName: "component---src-pages-modal-graphic-designer-js" */),
  "component---src-pages-modal-in-house-counsel-js": () => import("./../../../src/pages/modal/in-house-counsel.js" /* webpackChunkName: "component---src-pages-modal-in-house-counsel-js" */),
  "component---src-pages-modal-junior-graphic-designer-js": () => import("./../../../src/pages/modal/junior-graphic-designer.js" /* webpackChunkName: "component---src-pages-modal-junior-graphic-designer-js" */),
  "component---src-pages-modal-key-account-manager-js": () => import("./../../../src/pages/modal/key-account-manager.js" /* webpackChunkName: "component---src-pages-modal-key-account-manager-js" */),
  "component---src-pages-modal-legal-and-compliance-associate-js": () => import("./../../../src/pages/modal/legal-and-compliance-associate.js" /* webpackChunkName: "component---src-pages-modal-legal-and-compliance-associate-js" */),
  "component---src-pages-modal-mobile-software-engineer-js": () => import("./../../../src/pages/modal/mobile-software-engineer.js" /* webpackChunkName: "component---src-pages-modal-mobile-software-engineer-js" */),
  "component---src-pages-modal-quality-assurance-tester-js": () => import("./../../../src/pages/modal/quality-assurance-tester.js" /* webpackChunkName: "component---src-pages-modal-quality-assurance-tester-js" */),
  "component---src-pages-modal-search-engine-optimization-js": () => import("./../../../src/pages/modal/search-engine-optimization.js" /* webpackChunkName: "component---src-pages-modal-search-engine-optimization-js" */),
  "component---src-pages-modal-senior-mobile-developer-js": () => import("./../../../src/pages/modal/senior-mobile-developer.js" /* webpackChunkName: "component---src-pages-modal-senior-mobile-developer-js" */),
  "component---src-pages-modal-senior-software-developer-js": () => import("./../../../src/pages/modal/senior-software-developer.js" /* webpackChunkName: "component---src-pages-modal-senior-software-developer-js" */),
  "component---src-pages-modal-senior-solutions-engineer-js": () => import("./../../../src/pages/modal/senior-solutions-engineer.js" /* webpackChunkName: "component---src-pages-modal-senior-solutions-engineer-js" */),
  "component---src-pages-modal-software-developer-js": () => import("./../../../src/pages/modal/software-developer.js" /* webpackChunkName: "component---src-pages-modal-software-developer-js" */),
  "component---src-pages-modal-tech-lead-js": () => import("./../../../src/pages/modal/tech-lead.js" /* webpackChunkName: "component---src-pages-modal-tech-lead-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-policy-policy-section-js": () => import("./../../../src/pages/policy/PolicySection.js" /* webpackChunkName: "component---src-pages-policy-policy-section-js" */),
  "component---src-pages-promos-buenomanotopup-js": () => import("./../../../src/pages/promos/buenomanotopup.js" /* webpackChunkName: "component---src-pages-promos-buenomanotopup-js" */),
  "component---src-pages-promos-cash-back-js": () => import("./../../../src/pages/promos/CashBack.js" /* webpackChunkName: "component---src-pages-promos-cash-back-js" */),
  "component---src-pages-promos-cashbacktobusiness-js": () => import("./../../../src/pages/promos/cashbacktobusiness.js" /* webpackChunkName: "component---src-pages-promos-cashbacktobusiness-js" */),
  "component---src-pages-promos-digi-padala-agent-knb-js": () => import("./../../../src/pages/promos/DigiPadalaAgentKNB.js" /* webpackChunkName: "component---src-pages-promos-digi-padala-agent-knb-js" */),
  "component---src-pages-promos-digi-padala-suki-js": () => import("./../../../src/pages/promos/DigiPadalaSuki.js" /* webpackChunkName: "component---src-pages-promos-digi-padala-suki-js" */),
  "component---src-pages-promos-digi-tindahan-tagulan-js": () => import("./../../../src/pages/promos/DigiTindahanTagulan.js" /* webpackChunkName: "component---src-pages-promos-digi-tindahan-tagulan-js" */),
  "component---src-pages-promos-digipayaman-js": () => import("./../../../src/pages/promos/Digipayaman.js" /* webpackChunkName: "component---src-pages-promos-digipayaman-js" */),
  "component---src-pages-promos-js": () => import("./../../../src/pages/promos.js" /* webpackChunkName: "component---src-pages-promos-js" */),
  "component---src-pages-promos-referral-fee-mo-times-two-js": () => import("./../../../src/pages/promos/ReferralFeeMoTimesTwo.js" /* webpackChunkName: "component---src-pages-promos-referral-fee-mo-times-two-js" */),
  "component---src-pages-promos-six-sik-sa-papremyo-js": () => import("./../../../src/pages/promos/SixSikSaPapremyo.js" /* webpackChunkName: "component---src-pages-promos-six-sik-sa-papremyo-js" */),
  "component---src-pages-questions-section-js": () => import("./../../../src/pages/QuestionsSection.js" /* webpackChunkName: "component---src-pages-questions-section-js" */),
  "component---src-pages-stories-3-pwedeng-gawin-sa-bahay-js": () => import("./../../../src/pages/stories/3PwedengGawinSaBahay.js" /* webpackChunkName: "component---src-pages-stories-3-pwedeng-gawin-sa-bahay-js" */),
  "component---src-pages-stories-3-ways-to-earn-using-digipay-js": () => import("./../../../src/pages/stories/3WaysToEarnUsingDigipay.js" /* webpackChunkName: "component---src-pages-stories-3-ways-to-earn-using-digipay-js" */),
  "component---src-pages-stories-4-digipay-suki-you-probably-know-js": () => import("./../../../src/pages/stories/4DigipaySukiYouProbablyKnow.js" /* webpackChunkName: "component---src-pages-stories-4-digipay-suki-you-probably-know-js" */),
  "component---src-pages-stories-5-reasons-why-you-should-download-and-sign-up-with-digipay-js": () => import("./../../../src/pages/stories/5-reasons-why-you-should-download-and-sign-up-with-digipay.js" /* webpackChunkName: "component---src-pages-stories-5-reasons-why-you-should-download-and-sign-up-with-digipay-js" */),
  "component---src-pages-stories-6-na-paraan-kung-paano-kumita-gamit-ang-digipay-app-js": () => import("./../../../src/pages/stories/6-na-paraan-kung-paano-kumita-gamit-ang-digipay-app.js" /* webpackChunkName: "component---src-pages-stories-6-na-paraan-kung-paano-kumita-gamit-ang-digipay-app-js" */),
  "component---src-pages-stories-6-tips-for-digipay-agents-js": () => import("./../../../src/pages/stories/6-tips-for-digipay-agents.js" /* webpackChunkName: "component---src-pages-stories-6-tips-for-digipay-agents-js" */),
  "component---src-pages-stories-benefits-of-going-paperless-js": () => import("./../../../src/pages/stories/BenefitsOfGoingPaperless.js" /* webpackChunkName: "component---src-pages-stories-benefits-of-going-paperless-js" */),
  "component---src-pages-stories-business-ideas-with-small-capital-js": () => import("./../../../src/pages/stories/BusinessIdeasWithSmallCapital.js" /* webpackChunkName: "component---src-pages-stories-business-ideas-with-small-capital-js" */),
  "component---src-pages-stories-business-owner-tips-js": () => import("./../../../src/pages/stories/BusinessOwnerTips.js" /* webpackChunkName: "component---src-pages-stories-business-owner-tips-js" */),
  "component---src-pages-stories-digipay-agents-in-5-love-languages-js": () => import("./../../../src/pages/stories/DigipayAgentsIn5LoveLanguages.js" /* webpackChunkName: "component---src-pages-stories-digipay-agents-in-5-love-languages-js" */),
  "component---src-pages-stories-digipay-app-features-js": () => import("./../../../src/pages/stories/DigipayAppFeatures.js" /* webpackChunkName: "component---src-pages-stories-digipay-app-features-js" */),
  "component---src-pages-stories-digitindahan-js": () => import("./../../../src/pages/stories/Digitindahan.js" /* webpackChunkName: "component---src-pages-stories-digitindahan-js" */),
  "component---src-pages-stories-five-types-of-agents-js": () => import("./../../../src/pages/stories/FiveTypesOfAgents.js" /* webpackChunkName: "component---src-pages-stories-five-types-of-agents-js" */),
  "component---src-pages-stories-guide-from-ato-z-js": () => import("./../../../src/pages/stories/GuideFromAtoZ.js" /* webpackChunkName: "component---src-pages-stories-guide-from-ato-z-js" */),
  "component---src-pages-stories-mga-tips-sa-paggamit-ng-digipay-app-js": () => import("./../../../src/pages/stories/mga-tips-sa-paggamit-ng-digipay-app.js" /* webpackChunkName: "component---src-pages-stories-mga-tips-sa-paggamit-ng-digipay-app-js" */),
  "component---src-pages-stories-mistakes-to-avoid-as-a-digipay-agent-js": () => import("./../../../src/pages/stories/MistakesToAvoidAsADigipayAgent.js" /* webpackChunkName: "component---src-pages-stories-mistakes-to-avoid-as-a-digipay-agent-js" */),
  "component---src-pages-stories-paano-ienjoy-ang-summer-with-digipay-js": () => import("./../../../src/pages/stories/paano-ienjoy-ang-summer-with-digipay.js" /* webpackChunkName: "component---src-pages-stories-paano-ienjoy-ang-summer-with-digipay-js" */),
  "component---src-pages-stories-pera-padala-js": () => import("./../../../src/pages/stories/PeraPadala.js" /* webpackChunkName: "component---src-pages-stories-pera-padala-js" */),
  "component---src-pages-stories-tips-para-maging-exciting-ang-pasko-js": () => import("./../../../src/pages/stories/TipsParaMagingExcitingAngPasko.js" /* webpackChunkName: "component---src-pages-stories-tips-para-maging-exciting-ang-pasko-js" */),
  "component---src-pages-stories-why-agents-love-digipay-js": () => import("./../../../src/pages/stories/WhyAgentsLoveDigipay.js" /* webpackChunkName: "component---src-pages-stories-why-agents-love-digipay-js" */),
  "component---src-pages-stories-why-digipay-js": () => import("./../../../src/pages/stories/why-digipay.js" /* webpackChunkName: "component---src-pages-stories-why-digipay-js" */),
  "component---src-pages-stories-why-is-digipay-a-good-business-js": () => import("./../../../src/pages/stories/WhyIsDigipayAGoodBusiness.js" /* webpackChunkName: "component---src-pages-stories-why-is-digipay-a-good-business-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

